import {
  BlocksConfig,
  WidgetControllerConfig,
  WidgetMetaData,
} from '@wix/blocks-widget-services-types';
import { ILogger } from '../common/ILogger';
import { FetchDescriptorError } from '../errors/fetchDescriptorError';
import { generateWidgetDescriptorUrl } from './urlBuilder';
import { fetchWithRetries } from './fetchWithRetries';
import { isFetchError } from '../errors/fetchError';

const EMPTY_META_DATA: WidgetMetaData = {};

const FETCH_WIDGET_DESCRIPTOR_INTERACTION = 'fetch-widget-descriptor';

async function fetchDescriptor(descriptorUrl: string, logger?: ILogger) {
  try {
    return await fetchWithRetries<WidgetMetaData>(descriptorUrl);
  } catch (e) {
    // This means that the descriptor does not exists for the widget
    if (isFetchError(e) && e.statusCode === 403) {
      logger?.reportInfo('missing widget descriptor', { descriptorUrl });
      return EMPTY_META_DATA;
    }

    throw e;
  }
}

export async function fetchRemoteMetaData(
  widgetConfig: WidgetControllerConfig,
  blocksConfig: BlocksConfig,
  logger?: ILogger,
): Promise<WidgetMetaData> {
  if (!widgetConfig.config.devCenterWidgetId) {
    logger?.reportInfo('missing devCenterWidgetId', {
      type: widgetConfig.config.type,
    });

    return EMPTY_META_DATA;
  }

  const descriptorUrl = generateWidgetDescriptorUrl(
    widgetConfig.config.devCenterWidgetId,
    blocksConfig,
  );

  try {
    logger?.interactionStarted(FETCH_WIDGET_DESCRIPTOR_INTERACTION);
    const descriptor = await fetchDescriptor(descriptorUrl, logger);
    logger?.interactionEnded(FETCH_WIDGET_DESCRIPTOR_INTERACTION);

    return descriptor;
  } catch (e) {
    throw new FetchDescriptorError(descriptorUrl, e);
  }
}
