import { ModuleList } from '@wix/auto-frontend-module-registry';
import {
  ControllerConfig,
  WixCodeAPI,
} from '@wix/blocks-widget-services-types';
import { experiments } from './common';
import { listPagesImportedNamespaces } from '@wix/ambassador-velo-apps-v1-page-imported-namespaces/http';
import parseBlocksConfig from './utils/blocksConfigParser';
import { META_SITE_APP_DEF_ID } from './constants';

const shouldFetchAllWixCodeNamespaces = () => {
  try {
    // TODO: improve test infra to make it easier to have experiments without breaking tests
    return (
      experiments.isOpen(
        'specs.blocks-client.fetchAllWixCodeNamespacesLiveSite2',
      ) ||
      experiments.isOpen(
        'specs.blocks-preview-client.fetchAllWixCodeNamespacesPreview2',
      )
    );
  } catch (e) {
    return false;
  }
};

const fetchWixCodeNamespace = async <T extends object>(
  namespace: keyof WixCodeAPI,
  controllerConfig: ControllerConfig<T>,
) => {
  // TODO: Update IWidgetControllerConfig to have `platformApiProvider`
  return (controllerConfig as any).platformApiProvider.getPlatformApi(
    namespace,
  );
};

const getRelevantModulesToLoad = <T extends object>(
  controllerConfig: ControllerConfig<T>,
) => {
  const {
    code: { gridId: gridAppId },
    metaSiteId,
  } = parseBlocksConfig(controllerConfig.appParams);

  const signedInstance = (
    controllerConfig.platformAPIs as any
  ).essentials.env.platformEnv.getAppToken(META_SITE_APP_DEF_ID);

  return controllerConfig.essentials.httpClient.request(
    listPagesImportedNamespaces({
      gridAppId,
      metaSiteId,
    }),
    {
      signedInstance,
    },
  );
};

export const getDynamicPlatformApis = async (
  controllerConfig: ControllerConfig<{}, {}>,
) => {
  if (!shouldFetchAllWixCodeNamespaces()) {
    return {};
  }
  const { wixCodeApi } = controllerConfig;
  const modulesToImport =
    controllerConfig.wixCodeApi.window.viewMode !== 'Site'
      ? ModuleList
      : (
          await getRelevantModulesToLoad(controllerConfig)
        ).data.pagesImportedNamespaces?.reduce(
          (acc, { importedNamespaces }) => {
            return [
              ...acc,
              ...(importedNamespaces
                ?.map((namespaceOrName) => namespaceOrName.name)
                .filter((a): a is string => !!a) ?? []),
            ];
          },
          [] as string[],
        ) || [];

  const uniqueModules = [...new Set(modulesToImport)];
  const uniqueModulesWithoutPrefix = uniqueModules.map((namespace) => {
    return namespace.replace('wix-', '') as keyof WixCodeAPI;
  });

  return Object.fromEntries(
    (
      await Promise.allSettled(
        uniqueModulesWithoutPrefix.map(async (namespace) => {
          if (!wixCodeApi[namespace]) {
            return [
              namespace,
              await fetchWixCodeNamespace(namespace, controllerConfig),
            ] as const;
          }

          return;
        }),
      )
    )
      .filter(
        (result): result is PromiseFulfilledResult<[keyof WixCodeAPI, any]> =>
          result.status === 'fulfilled' &&
          !!result.value &&
          result.value[1] !== null,
      )
      .map((result) => {
        return result.value;
      }),
  );
};
